import './Post.css';

import { graphql, PageProps } from 'gatsby';
import React, { useRef } from 'react';

import Author from '../../components/Author/Author';
import { Box, Stack } from '../../components/Core';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import PostsSection from '../../components/PostsSection';
import SectionWithTitle from '../../components/SectionWithTitle';
import SEO from '../../components/SEO';
import SocialShareIcons from '../../components/SocialShareIcons';
import StyledLink from '../../components/StyledLink';
import TeamMember from '../../components/TeamMember';
import Text from '../../components/Text';
import { TEXT_STYLES } from '../../components/Text/constants';
import ThemedButton from '../../components/ThemedButton';
import useReplaceWPGalleryLinks from '../../hooks/useReplaceWPGalleryLinks';
import { getCorrectLink } from '../../utils/links';
import {
  createImageWithArtDirection,
  serializeImageForImageComponent,
} from '../../utils/serializeImageForImageComponent';
import NavSummary from '../components/Navs/NavSummary';

type IPostTemplate = PageProps<Queries.PagePostQuery, IPageContext>;

const PostTemplate: FCWithClassName<IPostTemplate> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpPost;
  const pageTemplateData =
    'contenuArticle' in data.wpPage.template
      ? data.wpPage?.template?.contenuArticle
      : null;
  if (!pageData || !pageTemplateData || !pageContext.defaultLanguage)
    return null;

  const postContentRef = useReplaceWPGalleryLinks();

  const postRef = useRef(null);

  const post = pageData;
  const relatedPosts = post.informationsPost?.articlesSimilaires;
  const imageWithArtDirection = createImageWithArtDirection({
    desktopImage: post.featuredImage?.node,
    mobileImage: post.informationsPost?.sectionHero.imageMobile,
  });
  const authors = post.informationsPost?.auteur.membre;
  const formattedAuthorsNames = authors?.map(
    (author) =>
      `${author.staffInformations.prenom} ${author.staffInformations.nom}`
  );
  const formatAuthorsCredits = (authorsNames: string[] | undefined) => {
    const preposition = pageTemplateData?.sectionAuteur.preposition;
    if (!authorsNames?.length) return `${preposition} Uperform`;
    if (authorsNames.length === 1) return `${preposition} ${authorsNames[0]}`;
    const firstAuthorsNames = authorsNames.slice(0, authorsNames.length - 1);
    const lastAuthorName = authorsNames[authorsNames.length - 1];
    const authorsNameList = `${firstAuthorsNames.join(
      ','
    )} & ${lastAuthorName}`;
    return `${preposition} ${authorsNameList}`;
  };
  const formattedAuthorCredits = formatAuthorsCredits(formattedAuthorsNames);
  const authorsComponents = authors?.map((authorData) => {
    const { description, prenom, gsm, photo, email, nom } =
      authorData.staffInformations;
    const image = serializeImageForImageComponent({
      image: photo,
    });
    return (
      <Stack justify="center" className="space-y-12" key={email}>
        <Author
          className="hidden lg:flex"
          description={description}
          firstName={prenom}
          gsm={gsm}
          image={image}
          mail={email}
          lastName={nom}
        />
        <TeamMember
          className="lg:hidden"
          description={description}
          firstName={prenom}
          gsm={gsm}
          image={image}
          mail={email}
          lastName={nom}
          languages={authorData.staffInformations.languages}
          textLink={authorData.staffInformations.textLink}
          linkType={authorData.staffInformations.linkType}
          externalLink={authorData.staffInformations.externalLink}
          phoneLink={authorData.staffInformations.phoneLink}
        />
      </Stack>
    );
  });
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Stack className="space-y-gap mt-gap" ref={postRef}>
        <SectionWithTitle className="relative z-1">
          <Stack align="center" className="w-full space-y-12">
            <Text
              variant={TEXT_STYLES.H5}
              className="uppercase text-primaryMain"
            >
              {post.categories.nodes[0]?.name}
            </Text>
            <Text
              variant={TEXT_STYLES.H1}
              className="text-center !normal-case !text-5 md:!text-6"
            >
              {post.informationsPost?.sectionHero.titre}
            </Text>
            <StyledLink anchorId="#author" variant="internalLink">
              <Text>{formattedAuthorCredits}</Text>
            </StyledLink>
            <SocialShareIcons location={props.location.href} />
            {post.featuredImage && !post.informationsPost?.youtube && (
              <div className="w-full aspect-h-6 aspect-w-8 lg:aspect-w-14">
                <Image
                  image={serializeImageForImageComponent({
                    image: imageWithArtDirection,
                  })}
                />
              </div>
            )}
            <Box className="relative w-full max-w-3xl">
              <Text
                className="post--intro !font-bold !text-lg sm:!text-xl"
                style={
                  {
                    '--post-intro-background':
                      post.categories.nodes[0]?.categoryInformations.couleur,
                  } as React.CSSProperties
                }
              >
                {post.informationsPost?.introduction}
              </Text>
            </Box>
            {post.informationsPost?.youtube && (
              <div className="w-full max-w-3xl aspect-h-6 aspect-w-4 sm:aspect-w-6 md:aspect-w-8 xl:aspect-w-14 ">
                <iframe
                  src={`https://youtube.com/embed/${post.informationsPost?.youtube}`}
                  allowFullScreen
                />
              </div>
            )}

            <NavSummary postRef={postRef} />

            <Box
              className="w-full max-w-3xl text-lg prose prose-post"
              ref={postContentRef}
              dangerouslySetInnerHTML={{
                __html: post.informationsPost?.contenu,
              }}
            />
            <SocialShareIcons location={props.location.href} />
          </Stack>
        </SectionWithTitle>
        {authors?.length && (
          <SectionWithTitle
            heading={pageTemplateData?.sectionAuteur.titreSection}
            contentAlignment="center"
            id="author"
          >
            {authorsComponents}
          </SectionWithTitle>
        )}
        {relatedPosts?.length > 0 && (
          <PostsSection
            currentLanguageCode={props.pageContext.currentLanguage.code}
            posts={relatedPosts}
          />
        )}
        <StyledLink
          className="mx-auto"
          to={
            getCorrectLink(
              pageTemplateData?.sectionArticlesLies.blogLink.url ?? null
            ).to
          }
        >
          <ThemedButton>
            {pageTemplateData?.sectionArticlesLies.blogLink.title}
          </ThemedButton>
        </StyledLink>
      </Stack>
    </Layout>
  );
};

export default PostTemplate;

export const query = graphql`
  query PagePost($id: String, $currentLanguageSlug: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    wpPost(id: { eq: $id }) {
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
      categories {
        nodes {
          name
          categoryInformations {
            couleur
          }
          slug
          id
        }
      }
      informationsPost {
        auteur {
          membre {
            ... on WpStaff {
              id
              staffInformations {
                nom
                photo {
                  ...MediaItemFragment
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 240, layout: CONSTRAINED)
                    }
                    publicURL
                  }
                }
                email
                description
                gsm
                languages
                textLink
                linkType
                externalLink
                phoneLink
                poste {
                  ... on WpService {
                    id
                    title
                  }
                }
                prenom
              }
            }
          }
        }
        sectionHero {
          imageMobile {
            ...MediaItemFragment
            localFile {
              ...MobileHeroImage
            }
          }
          titre
        }
        services {
          ... on WpService {
            id
            slug
            title
            informationsService {
              couleur
            }
          }
        }
        articlesSimilaires {
          ... on WpPost {
            ...PostEntryFragment
          }
        }
        contenu
        introduction
        youtube
      }
      tags {
        nodes {
          slug
          name
          id
        }
      }
      author {
        node {
          name
          slug
        }
      }
    }
    wpPage(
      language: { slug: { eq: $currentLanguageSlug } }
      template: { templateName: { eq: "Page Article" } }
    ) {
      template {
        ... on WpTemplate_PageArticle {
          contenuArticle {
            sectionArticlesLies {
              blogLink {
                url
                title
              }
            }
            sectionAuteur {
              preposition
              titreSection
            }
          }
        }
      }
    }
  }
`;
